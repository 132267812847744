//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { routerAsync } from '@/app-module';
// import { i18n } from '@/i18n';

export default {
  name: 'app-signin-page',

  methods: {
    // i18n(code) {
    //   return i18n(code);
    // },
    i18n(key, args) {
        return this.$t(key, args);
    },   
    doSubmit(type) {
      if (type == 'phone') {
        return routerAsync().push('/auth/signin/phone-number')
      }
      if (type == 'email') {
        return routerAsync().push('/auth/signin/email-adress')
      }
    }
  },
};
